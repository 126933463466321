const initNarrowTextHome = () => {
  const ampersant = document.getElementById("ampersand-text");
  const title = document.querySelector(".narrow-text-home h2");

  if (title) {
    gsap.fromTo(
      title,
      {
        rotation: -5,
        scrollTrigger: {
          trigger: title,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      },
      {
        rotation: 0,
        delay: 1,
        duration: 1,
        scrollTrigger: {
          trigger: title,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      }
    );
    gsap.fromTo(
      ampersant,
      {
        scale: 0.5,
        y: 0,
        scrollTrigger: {
          trigger: title,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      },
      {
        scale: 1,
        duration: 1,
        y: -30,
        delay: 1,
        scrollTrigger: {
          trigger: title,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      }
    );
  }
};

document.addEventListener("DOMContentLoaded", function () {
  initNarrowTextHome();
});
